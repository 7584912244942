import logo from './logo.svg';
import './App.css';
import { VideoChat } from './VideoChat.js'
function App() {
  return (
      <VideoChat />
  );
}

export default App;
